import React from "react"

import LayoutMain from "components/layout/layoutMain"
import SEO from "components/Seo"

const NotFoundPage = () => (
  <LayoutMain>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p></p>
  </LayoutMain>
)

export default NotFoundPage
